<template>
  <el-dialog v-if="dataInfo" :title="title" @closed="_closed" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" width="600px">
    <el-form ref="dataInfo" :model="dataInfo" :rules="dataRules" label-width="120px">
      <el-row class="padding-top-xs">
        <el-form-item label="计划名称" prop="planName">
          <el-input v-model="dataInfo.planName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="盘点责任人" prop="responsibleUserName">
          <el-autocomplete v-model="dataInfo.responsibleUserName" :fetch-suggestions="querySearchAsync" value-key="fullName" placeholder="请输入内容" @select="handleSelect"></el-autocomplete>
          <!-- <el-input v-model="dataInfo.responsibleUserName" autocomplete="off"></el-input> -->
        </el-form-item>
        <el-form-item label="备注信息" prop="desc">
          <el-input type="textarea" v-model="dataInfo.desc" autocomplete="off"></el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">取 消</el-button>
      <el-button type="primary" @click="save">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      activeName: 'base',
      dataRules: {
        assetName: [{ required: true, trigger: 'blur', message: '资产名称不能为空' }],
        categoryCode: [{ required: true, trigger: 'blur', message: '资产分类不能为空' }],
        assetType: [{ required: true, trigger: 'blur', message: '资产类型不能为空' }]
      }
    }
  },
  methods: {
    querySearchAsync(queryString, cb) {
      this.$lgh
        .get('api/enterprise/employee/search', {
          enterpriseCode: this.dataInfo.enterpriseCode,
          keyword: queryString,
          count: 10
        })
        .then(res => {
          cb(res)
        })

      // var restaurants = this.restaurants
      // var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants

      // clearTimeout(this.timeout)
      // this.timeout = setTimeout(() => {
      //   cb(results)
      // }, 3000 * Math.random())
    },
    handleSelect(item) {
      this.dataInfo.responsibleUserId = item.userId
      this.dataInfo.responsibleUserName = item.fullName
    },
    save() {
      this.$refs.dataInfo.validate(async valid => {
        if (valid) {
          await this.$lgh.post('api/enterprise/assetplan/addoredit', this.dataInfo)
          this.visible = false
          this.onsuccess && this.onsuccess(this.dataInfo)
        }
      })
    }
  }
}
</script>

<style></style>
